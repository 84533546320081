// Bootstrap color system

//
// Bootstrap & Custom Variables
// Safely override any variable in _variables.custom.scss
//

$gray-100-dark:  #1B1C22 !default;
$gray-200-dark:  #26272F !default;
$gray-300-dark:  #363843 !default;
$gray-400-dark:  #464852 !default;
$gray-500-dark:  #636674 !default;
$gray-600-dark:  #808290 !default;
$gray-700-dark:  #9A9CAE !default;
$gray-800-dark:  #B5B7C8 !default;
$gray-900-dark:  #F5F5F5 !default;

$grays-dark: (
	"100": $gray-100-dark,
	"200": $gray-200-dark,
	"300": $gray-300-dark,
	"400": $gray-400-dark,
	"500": $gray-500-dark,
	"600": $gray-600-dark,
	"700": $gray-700-dark,
	"800": $gray-800-dark,
	"900": $gray-900-dark
) !default;

// Bootstrap muted color
$text-muted-dark: #999999 !default;

// Bootstrap contextual colors
// Primary colors
$primary-dark:						#006AE6 !default;
$primary-active-dark:				#107EFF !default;
$primary-light-dark: 				#172331 !default;
$primary-clarity-dark: 				rgba(#006AE6, 0.2) !default;
$primary-inverse-dark: 				$white !default;

// Secondary colors
$secondary-dark: 					$gray-300-dark !default;
$secondary-active-dark: 			$gray-400-dark !default;
$secondary-light-dark: 				$gray-300-dark !default;
$secondary-clarity-dark:			rgba($gray-300-dark, 0.2) !default; 
$secondary-inverse-dark:			$white !default; 

// Light colors
$light-dark: 						$gray-100-dark !default;
$light-active-dark: 				#1F212A !default;
$light-light-dark: 					#1F212A !default;
$light-clarity-dark: 				rgba(31, 33, 42, 0.20) !default;
$light-inverse-dark:				$gray-600-dark !default;

// Success colors
$success-dark: 						#00A261 !default; 
$success-active-dark: 				#01BF73 !default;
$success-light-dark: 				#1F212A !default;
$success-clarity-dark: 				rgba(#00A261, 0.2) !default;
$success-inverse-dark: 				$white !default;

// Info colors
$info-dark: 						#883FFF !default; 
$info-active-dark: 					#9E63FF !default;
$info-light-dark: 					#272134 !default;
$info-clarity-dark: 				rgba(#883FFF, 0.2) !default;
$info-inverse-dark: 				$white !default;

// Warning colors
$warning-dark: 						#C59A00 !default; 
$warning-active-dark: 				#D9AA00 !default;
$warning-light-dark: 				#242320 !default;
$warning-clarity-dark: 				rgba(#C59A00, 0.2) !default;
$warning-inverse-dark: 				$white !default;

// Danger colors
$danger-dark: 						#E42855 !default; 
$danger-active-dark: 				#FF3767 !default;
$danger-light-dark: 				#302024 !default;
$danger-clarity-dark: 				rgba(#E42855, 0.2) !default;
$danger-inverse-dark: 				$white !default;

// Dark colors
$dark-dark: 						#272A34 !default;
$dark-active-dark: 					#2D2F39 !default;
$dark-light-dark: 					#1E2027 !default;
$dark-clarity-dark: 				rgba(#272A34, 0.2) !default;
$dark-inverse-dark: 				$white !default;

// Custom dark colors
$red-dark: 							#FF3333 !default;
$red-active-dark: 					#8D1A1A !default;
$red-light-dark: 					#391B1F !default;
$red-clarity-dark: 					rgba(#ff5252, 0.2) !default;
$red-inverse-dark: 					$white !default;

$orange-dark: 						#FF8C00 !default;
$orange-active-dark: 				#B35900 !default;
$orange-light-dark: 				#392918 !default;
$orange-clarity-dark: 				rgba(#ffa726, 0.2) !default;
$orange-inverse-dark: 				$white !default;

$yellow-dark: 						#FFD700 !default;
$yellow-active-dark: 				#B38F00 !default;
$yellow-light-dark: 				#393418 !default;
$yellow-clarity-dark: 				rgba(#ffd54f, 0.2) !default;
$yellow-inverse-dark: 				$white !default;

$green-dark: 						#66D066 !default;
$green-active-dark: 				#347A34 !default;
$green-light-dark: 					#223327 !default;
$green-clarity-dark: 				rgba(#81c784, 0.2) !default;
$green-inverse-dark: 				$white !default;

$teal-dark: 						#1EC6C6 !default;
$teal-active-dark: 					#145B5B !default;
$teal-light-dark: 					#173136 !default;
$teal-clarity-dark: 				rgba(#26a69a, 0.2) !default;
$teal-inverse-dark: 				$white !default;

$blue-dark: 						#3399FF !default;
$blue-active-dark: 					#1A4D8D !default;
$blue-light-dark: 					#1A2B3E !default;
$blue-clarity-dark:					rgba(#42a5f5, 0.2) !default;
$blue-inverse-dark:					$white !default;

$purple-dark: 						#A64DFF !default;
$purple-active-dark: 				#5E2E8D !default;
$purple-light-dark:					#2C1F3E !default;
$purple-clarity-dark: 				rgba(#ba68c8, 0.2) !default;
$purple-inverse-dark: 				$white !default;

$magenta-dark: 						#FF66B2 !default;
$magenta-active-dark: 				#8D4066 !default;
$magenta-light-dark: 				#392333 !default;
$magenta-clarity-dark: 				rgba(#f48fb1, 0.2) !default;
$magenta-inverse-dark: 				$white !default;

$brown-dark: 						#BF8040 !default;
$brown-active-dark:					#704020 !default;
$brown-light-dark: 					#2F2721 !default;
$brown-clarity-dark: 				rgba(#a1887f, 0.2) !default;
$brown-inverse-dark: 				$white !default;

$gray-dark: 						#9E9E9E !default;
$gray-active-dark: 					#5A5A5A !default;
$gray-light-dark: 					#2A2B30 !default;
$gray-clarity-dark: 				rgba(#bdbdbd, 0.2) !default;
$gray-inverse-dark: 				$white !default;

$tomato-dark: 						#FF453A !default;
$tomato-active-dark: 				#8D2924 !default;
$tomato-light-dark: 				#391E21 !default;
$tomato-clarity-dark: 				rgba(#ef5350, 0.2) !default;
$tomato-inverse-dark: 				$white !default;

$orange-red-dark: 					#FF5733 !default;
$orange-red-active-dark: 			#8D391A !default;
$orange-red-light-dark:				#39211F !default;
$orange-red-clarity-dark: 			rgba(#ff7043, 0.2) !default;
$orange-red-inverse-dark: 			$white !default;

$gold-dark: 						#FFBF00 !default;
$gold-active-dark: 					#8D7400 !default;
$gold-light-dark: 					#393018 !default;
$gold-clarity-dark: 				rgba(#ffca28, 0.2) !default;
$gold-inverse-dark: 				$white !default;

$yellow-green-dark: 				#A5D700 !default;
$yellow-green-active-dark: 			#667A00 !default;
$yellow-green-light-dark: 			#2B3418 !default;
$yellow-green-clarity-dark: 		rgba(#c0ca33, 0.2) !default;
$yellow-green-inverse-dark: 		$white !default;

$olive-dark: 						#B3D478 !default;
$olive-active-dark: 				#6A8D3D !default;
$olive-light-dark: 					#2E332A !default;
$olive-clarity-dark: 				rgba(#aed581, 0.2) !default;
$olive-inverse-dark: 				$white !default;

$cyan-dark: 						#00E0E0 !default;
$cyan-active-dark: 					#007373 !default;
$cyan-light-dark: 					#133539 !default;
$cyan-clarity-dark: 				rgba(#26c6da, 0.2) !default;
$cyan-inverse-dark: 				$white !default;

$plum-dark: 						#6050DC !default;
$plum-active-dark: 					#3A307A !default;
$plum-light-dark: 					#212039 !default;
$plum-clarity-dark: 				rgba(#7986cb, 0.2) !default;
$plum-inverse-dark: 				$white !default;

$lavender-dark: 					#C586FF !default;
$lavender-active-dark: 				#70478D !default;
$lavender-light-dark: 				#30283E !default;
$lavender-clarity-dark: 			rgba(#b39ddb, 0.2) !default;
$lavender-inverse-dark: 			$white !default;

$pink-dark: 						#FF3381 !default;
$pink-active-dark: 					#8D1A47 !default;
$pink-light-dark: 					#391B2B !default;
$pink-clarity-dark: 				rgba(#ec407a, 0.2) !default;
$pink-inverse-dark: 				$white !default;

$dark-gray-dark: 					#858585 !default;
$dark-gray-active-dark: 			#505050 !default;
$dark-gray-light-dark: 				#27282C !default;
$dark-gray-clarity-dark: 			rgba(#a5a5a5, 0.2) !default;
$dark-gray-inverse-dark: 			$white !default;

// Contextual colors
$theme-colors-dark: (
	"light": $light-dark,
	"primary": $primary-dark,
	"secondary": $secondary-dark,
	"success": $success-dark,
	"info": $info-dark,
	"warning": $warning-dark,
	"danger": $danger-dark,
	"dark": $dark-dark,
	"red": $red-dark,
	"orange": $orange-dark,
	"yellow": $yellow-dark,
	"green": $green-dark,
	"teal": $teal-dark,
	"blue": $blue-dark,
	"purple": $purple-dark,
	"magenta": $magenta-dark,
	"brown": $brown-dark,
	"gray": $gray-dark,
	"orange-red": $orange-red-dark,
	"tomato": $tomato-dark,
	"gold": $gold-dark,
	"yellow-green": $yellow-green-dark,
	"olive": $olive-dark,
	"cyan": $cyan-dark,
	"plum": $plum-dark,
	"lavender": $lavender-dark,
	"pink": $pink-dark,
	"dark-gray": $dark-gray-dark
) !default;
	
// Contextual active state colors
$theme-active-colors-dark: (
	"primary": $primary-active-dark,
	"secondary":  $secondary-active-dark,
	"light":   $light-active-dark,
	"success": $success-active-dark,
	"info":    $info-active-dark,
	"warning": $warning-active-dark,
	"danger":  $danger-active-dark,
	"dark":    $dark-active-dark,
	"red": 		$red-active-dark,
	"orange": $orange-active-dark,
	"yellow": $yellow-active-dark,
	"green": $green-active-dark,
	"teal": $teal-active-dark,
	"blue": $blue-active-dark,
	"purple": $purple-active-dark,
	"magenta": $magenta-active-dark,
	"brown": $brown-active-dark,
	"gray": $gray-active-dark,
	"orange-red": $orange-red-active-dark,
	"tomato": $tomato-active-dark,
	"gold": $gold-active-dark,
	"yellow-green": $yellow-green-active-dark,
	"olive": $olive-active-dark,
	"cyan": $cyan-active-dark,
	"plum": $plum-active-dark,
	"lavender": $lavender-active-dark,
	"pink": $pink-active-dark,
	"dark-gray": $dark-gray-active-dark
) !default; 

// Contextual inverse state colors
$theme-inverse-colors-dark: (
	"primary": $primary-inverse-dark,
	"secondary":  $secondary-inverse-dark,
	"light":   $light-inverse-dark,
	"success": $success-inverse-dark,
	"info":    $info-inverse-dark,
	"warning": $warning-inverse-dark,
	"danger":  $danger-inverse-dark,
	"dark":    $dark-inverse-dark,
	"red": $red-inverse-dark,
	"orange": $orange-inverse-dark,
	"yellow": $yellow-inverse-dark,
	"green": $green-inverse-dark,
	"teal": $teal-inverse-dark,
	"blue": $blue-inverse-dark,
	"purple": $purple-inverse-dark,
	"magenta": $magenta-inverse-dark,
	"brown": $brown-inverse-dark,
	"gray": $gray-inverse-dark,
	"orange-red": $orange-red-inverse-dark,
	"tomato": $tomato-inverse-dark,
	"gold": $gold-inverse-dark,
	"yellow-green": $yellow-green-inverse-dark,
	"olive": $olive-inverse-dark,
	"cyan": $cyan-inverse-dark,
	"plum": $plum-inverse-dark,
	"lavender": $lavender-inverse-dark,
	"pink": $pink-inverse-dark,
	"dark-gray": $dark-gray-inverse-dark
) !default; 

// Contextual light state colors
$theme-light-colors-dark: (
	"primary": $primary-light-dark,
	"secondary": $secondary-light-dark,
	"success": $success-light-dark,
	"info":    $info-light-dark,
	"warning": $warning-light-dark,
	"danger":  $danger-light-dark,
	"dark":    $dark-light-dark,
	"light":   $light-light-dark,
	"red": $red-light-dark,
	"orange": $orange-light-dark,
	"yellow": $yellow-light-dark,
	"green": $green-light-dark,
	"teal": $teal-light-dark,
	"blue": $blue-light-dark,
	"purple": $purple-light-dark,
	"magenta": $magenta-light-dark,
	"brown": $brown-light-dark,
	"gray": $gray-light-dark,
	"orange-red": $orange-red-light-dark,
	"tomato": $tomato-light-dark,
	"gold": $gold-light-dark,
	"yellow-green": $yellow-green-light-dark,
	"olive": $olive-light-dark,
	"cyan": $cyan-light-dark,
	"plum": $plum-light-dark,
	"lavender": $lavender-light-dark,
	"pink": $pink-light-dark,
	"dark-gray": $dark-gray-light-dark
) !default; 

// Contextual light state colors
$theme-clarity-colors-dark: (
	"primary": $primary-clarity-dark,
	"secondary": $secondary-clarity-dark,
	"success": $success-clarity-dark,
	"info":    $info-clarity-dark,
	"warning": $warning-clarity-dark,
	"danger":  $danger-clarity-dark,
	"dark":    $dark-clarity-dark,
	"light":   $light-clarity-dark,
	"red": $red-clarity-dark,
	"orange": $orange-clarity-dark,
	"yellow": $yellow-clarity-dark,
	"green": $green-clarity-dark,
	"teal": $teal-clarity-dark,
	"blue": $blue-clarity-dark,
	"purple": $purple-clarity-dark,
	"magenta": $magenta-clarity-dark,
	"brown": $brown-clarity-dark,
	"gray": $gray-clarity-dark,
	"orange-red": $orange-red-clarity-dark,
	"tomato": $tomato-clarity-dark,
	"gold": $gold-clarity-dark,
	"yellow-green": $yellow-green-clarity-dark,
	"olive": $olive-clarity-dark,
	"cyan": $cyan-clarity-dark,
	"plum": $plum-clarity-dark,
	"lavender": $lavender-clarity-dark,
	"pink": $pink-clarity-dark,
	"dark-gray": $dark-gray-clarity-dark,
) !default; 

// Text colors
$theme-text-colors-dark: (
	"white":   $white,
	"primary": $primary-dark,
	"secondary":  $secondary-dark,
	"light":   $light-dark,
	"success": $success-dark,
	"info":    $info-dark,
	"warning": $warning-dark,
	"danger":  $danger-dark,
	"dark":    $dark-dark,
	"red": $red-dark,
	"orange": $orange-dark,
	"yellow": $yellow-dark,
	"green": $green-dark,
	"teal": $teal-dark,
	"blue": $blue-dark,
	"purple": $purple-dark,
	"magenta": $magenta-dark,
	"brown": $brown-dark,
	"gray": $gray-dark,
	"orange-red": $orange-red-dark,
	"tomato": $tomato-dark,
	"gold": $gold-dark,
	"yellow-green": $yellow-green-dark,
	"olive": $olive-dark,
	"cyan": $cyan-dark,
	"plum": $plum-dark,
	"lavender": $lavender-dark,
	"pink": $pink-dark,
	"dark-gray": $dark-gray-dark,
	"muted":   $text-muted-dark,
	"gray-100":   $gray-100-dark,
	"gray-200":   $gray-200-dark,
	"gray-300":   $gray-300-dark,
	"gray-400":   $gray-400-dark,
	"gray-500":   $gray-500-dark,
	"gray-600":   $gray-600-dark,
	"gray-700":   $gray-700-dark,
	"gray-800":   $gray-800-dark,
	"gray-900":   $gray-900-dark
) !default; 


// Body
//
// Settings for the `<body>` element.
$body-bg-dark:              #1C1D22 !default;
$body-bg-rgb-dark:          to-rgb($body-bg-dark) !default;
$body-color-dark:           $gray-900-dark !default;


// Links
//
// Style anchor elements.
$link-color-dark:           $primary-dark !default;


// Components
//
// Define common padding and border radius sizes and more.
$border-color-dark:             $gray-200-dark !default;
$border-dashed-color-dark:      $gray-300-dark !default; 

// Keenthemes hover states
$component-hover-color-dark: 	$primary-dark !default;
$component-hover-bg-dark: 		$gray-100-dark !default;

// Keenthemes active states
$component-active-color-dark:   $primary-inverse !default;
$component-active-bg-dark:      $primary-dark !default;

// Keenthemes checked states
$component-checked-color-dark:   $primary-inverse !default;
$component-checked-bg-dark:      $primary-dark !default;

$headings-color-dark: 			 $gray-900-dark !default;
$blockquote-footer-color-dark:   $gray-600-dark !default;


// Box shadow
$box-shadow-xs-dark: 			0 .1rem 0.75rem 0.25rem rgba($black, 0.05) !default;
$box-shadow-sm-dark: 			0 .1rem 1rem 0.25rem rgba($black, .05) !default;
$box-shadow-dark: 				0 .5rem 1.5rem 0.5rem rgba($black, .075) !default;
$box-shadow-lg-dark: 			0 1rem 2rem 1rem rgba($black, .1) !default;
$box-shadow-inset-dark: 		inset 0 1px 2px rgba($black, .075) !default;


// Card
$card-box-shadow-dark:                   			null !default;
$card-border-color-dark:     						$border-color-dark !default;

// Tables
$table-striped-bg-dark:         					rgba($gray-100-dark, 0.75) !default;
$table-loading-message-box-shadow-dark:        		0px 0px 30px rgba(0, 0, 0, 0.3) !default;
	
// Forms
$form-select-indicator-color-dark:                  $gray-600-dark !default;
$form-select-indicator-dark:                        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color-dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;
$form-switch-color-dark:                            rgba($white, .25) !default;
$form-switch-color-solid-dark:                      $gray-500-dark !default;
$form-switch-bg-image-dark:                         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-dark}'/></svg>") !default;
$form-switch-bg-image-solid-dark:                   url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-solid-dark}'/></svg>") !default;


// Accordion
$accordion-icon-color-dark:                         $body-color-dark!default;
$accordion-icon-active-color-dark:                  $primary-dark !default;
$accordion-button-icon-dark:         				url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon-dark:  				url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;


// Dropdowns
$dropdown-bg-dark:                                  $body-bg-dark !default;
$dropdown-box-shadow-dark:							0px 0px 30px rgba(0, 0, 0, 0.3) !default;

// Popover
$popover-bg-dark:                            		$gray-200-dark !default;
$popover-border-color-dark:                         $gray-200-dark !default;
$popover-box-shadow-dark:    						$dropdown-box-shadow-dark !default;
$popover-header-border-color-dark:           		$gray-300-dark !default;

// Toasts
$toast-background-color-dark:                		$gray-200-dark !default;
$toast-header-background-color-dark:         		$gray-200-dark !default;
$toast-header-border-color-dark:             		$gray-300-dark !default;

// Tooltip
$tooltip-bg-dark:                            		$gray-200-dark !default;
$tooltip-box-shadow-dark:    						0px 0px 30px rgba(0, 0, 0, 0.15) !default;

// Code
$code-bg-dark:                   #2b2b40 !default;
$code-shadow-dark:               rgba(0, 0, 0, 0.08) 0px 3px 9px 0px !default;
$code-color-dark:                #b93993 !default;
$code-border-color-dark:         transparent  !default;

// Symbol
$symbol-border-color-dark: rgba($body-bg, 0.5);
$symbol-label-color-dark: $gray-800-dark;
$symbol-label-bg-dark: $gray-100-dark;


// Keenthemes bullet component
$bullet-bg-color-dark: $gray-400-dark !default;

// Keenthemes scrolltop component
$scrolltop-opacity-dark: 0 !default;
$scrolltop-opacity-on-dark: 0.3 !default;
$scrolltop-opacity-hover-dark: 1 !default;
$scrolltop-box-shadow-dark: $box-shadow !default;
$scrolltop-bg-color-dark: $primary !default;
$scrolltop-bg-color-hover-dark: $primary !default;
$scrolltop-icon-color-dark: $primary-inverse !default;
$scrolltop-icon-color-hover-dark: $primary-inverse !default;


// Keenthemes drawer component
$drawer-box-shadow-dark: 0px 0px 30px rgba(0, 0, 0, 0.1) !default;
$drawer-bg-color-dark: $body-bg-dark !default;
$drawer-overlay-bg-color-dark: rgba($black, 0.4) !default;

// Keenthemes menu component   
$menu-dropdown-box-shadow-dark: $dropdown-box-shadow-dark !default;
$menu-dropdown-bg-color-dark: $dropdown-bg-dark !default;
$menu-link-color-hover-dark: $component-hover-color-dark !default;
$menu-link-color-show-dark: $component-hover-color-dark !default;
$menu-link-color-here-dark: $component-hover-color-dark !default;
$menu-link-color-active-dark: $component-hover-color-dark !default;
$menu-link-bg-color-hover-dark: $component-hover-bg-dark !default;
$menu-link-bg-color-show-dark: $component-hover-bg-dark !default;
$menu-link-bg-color-here-dark: $component-hover-bg-dark !default;
$menu-link-bg-color-active-dark: $component-hover-bg-dark !default;
$menu-heading-color-dark: $text-muted-dark !default;
	
// Keenthemes scrollbar component 
$scrollbar-color-dark: $gray-200-dark !default;
$scrollbar-hover-color-dark: $gray-300-dark !default;

// Keenthemes overlay component 
$overlay-bg-dark: rgba($white, 0.05) !default;

// Keenthemes blockui component 
$blockui-overlay-bg-dark: rgba($white, 0.05) !default;
$blockui-message-bg-dark: $tooltip-bg-dark !default;
$blockui-message-box-shadow-dark: $tooltip-box-shadow-dark !default;

// Keenthemes rating component 
$rating-color-default-dark: $gray-400-dark !default;
$rating-color-active-dark: #FFAD0F !default;

// Keenthemes ribbon component 
$ribbon-label-box-shadow-dark: 0px -1px 5px 0px rgba($white, 0.1) !default;
$ribbon-label-bg-dark: $primary !default;
$ribbon-label-border-color-dark: darken($primary, 20%) !default;
$ribbon-clip-bg-dark: $light !default;

// Engage panel
$engage-btn-bg-dark: $gray-200-dark;
$engage-btn-box-shadow-dark: 0px 0px 30px rgba(0, 0, 0, 0.3);
$engage-btn-border-color-dark: $border-color-dark;
$engage-btn-color-dark: $gray-800-dark;
$engage-btn-icon-color-dark: $gray-600-dark;
$engage-btn-color-active-dark: $gray-800-dark;