//
// Content
//


// Desktop mode
@include media-breakpoint-up(lg) {
    .app-content {
        [data-kt-app-toolbar-enabled="true"]:not([data-kt-app-toolbar-fixed="true"]) & {
            padding-top: 0;
        }
    }

    [data-kt-app-header-minimize=on] {
        .filter-form-wrapper {
            transition: none;
            z-index: 100;
            box-shadow: var(--#{$prefix}app-header-minimize-box-shadow);
            border-bottom: var(--#{$prefix}app-header-minimize-border-bottom);
            background-color: var(--#{$prefix}app-header-minimize-bg-color);
        }
    }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
    .app-content {
        [data-kt-app-toolbar-enabled="true"]:not([data-kt-app-toolbar-fixed-mobile="true"]) & {
            padding-top: 0;
        }
    }
}